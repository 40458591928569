import * as api from 'lib/go/utils';
import { fiber_node } from 'lib/types/go/telco';

const namespace = 'telco';
const resource = 'fiber_nodes';

type IDOnly = fiber_node.FiberNodeIDOnly;
type Full = fiber_node.FiberNode;
type Debug = fiber_node.FiberNodeDebug;

type CSIDOnly = fiber_node.ChildStructureIDOnly;
type CSFull = fiber_node.ChildStructure;
type CSDebug = fiber_node.ChildStructureDebug;

type PSIDOnly = fiber_node.ParentStructureIDOnly;
type PSFull = fiber_node.ParentStructure;
type PSDebug = fiber_node.ParentStructureDebug;

type TOIDOnly = fiber_node.TraceOutIDOnly;
type TOFull = fiber_node.TraceOut;
type TODebug = fiber_node.TraceOutDebug;

export const childStructure = api.childStructure<CSIDOnly, CSFull, CSDebug>(namespace, resource);
export const parentStructure = api.parentStructure<PSIDOnly, PSFull, PSDebug>(namespace, resource);
export const retrieve = api.retrieve<IDOnly, Full, Debug>(namespace, resource);
export const stats = api.stats<fiber_node.Stats>(namespace, resource);
export const traceOut = api.traceOut<TOIDOnly, TOFull, TODebug>(namespace, resource);
