import { Action as MapAction } from 'lib/map/actions';

import { MapActionListener, MapActionStore, MapActionSubscription } from './interfaces';

export default function createStore(): MapActionStore {
  class Store {
    private _listeners: MapActionListener[] = [];
    private _queue: MapAction[] = [];

    dispatch(action: MapAction) {
      if (this._listeners.length === 0) {
        // queue if store has no listeners
        this._queue.push(action);
      } else {
        // dispatch action immediately otherwise
        this._dispatchAction(action);
      }
    }

    subscribe(listener: MapActionListener): MapActionSubscription {
      this._listeners.push(listener);
      const subscription: MapActionSubscription = {
        unsubscribe: () => {
          this._listeners = this._listeners.filter((_listener) => _listener !== listener);
        },
      };

      // empty queue
      this._queue.reverse();
      while (this._queue.length !== 0) {
        const action = this._queue.pop();
        if (action !== undefined) {
          this._dispatchAction(action);
        }
      }

      return subscription;
    }

    private _dispatchAction(action: MapAction) {
      this._listeners.forEach((listener) => listener(action));
    }
  }

  return new Store();
}
