import * as api from 'lib/go/utils';
import { fiber_cable_node } from 'lib/types/go/telco';

import { NAMESPACE, RESOURCE } from './constants';

export * from './fiber_trace_out';

type IDOnly = fiber_cable_node.FiberCableNodeIDOnly;
type Full = fiber_cable_node.FiberCableNode;
type Debug = fiber_cable_node.FiberCableNodeDebug;

type CSIDOnly = fiber_cable_node.ChildStructureIDOnly;
type CSFull = fiber_cable_node.ChildStructure;
type CSDebug = fiber_cable_node.ChildStructureDebug;

type PSIDOnly = fiber_cable_node.ParentStructureIDOnly;
type PSFull = fiber_cable_node.ParentStructure;
type PSDebug = fiber_cable_node.ParentStructureDebug;

export const childStructure = api.childStructure<CSIDOnly, CSFull, CSDebug>(NAMESPACE, RESOURCE);
export const has = api.has(NAMESPACE, RESOURCE);
export const parentStructure = api.parentStructure<PSIDOnly, PSFull, PSDebug>(NAMESPACE, RESOURCE);
export const retrieve = api.retrieve<IDOnly, Full, Debug>(NAMESPACE, RESOURCE);
export const stats = api.stats<fiber_cable_node.Stats>(NAMESPACE, RESOURCE);
