import * as api from 'lib/go/utils';
import { zone } from 'lib/types/go/telco';

const namespace = 'telco';
const resource = 'zones';

type IDOnly = zone.ZoneIDOnly;
type Full = zone.Zone;
type Debug = zone.ZoneDebug;

type CSIDOnly = zone.ChildStructureIDOnly;
type CSFull = zone.ChildStructure;
type CSDebug = zone.ChildStructureDebug;

type LocatedInIDOnly = zone.ZoneIDOnly;
type LocatedInFull = zone.Zone;
type LocatedInDebug = zone.ZoneDebug;

type PSIDOnly = zone.ParentStructureIDOnly;
type PSFull = zone.ParentStructure;
type PSDebug = zone.ParentStructureDebug;

export const childStructure = api.childStructure<CSIDOnly, CSFull, CSDebug>(namespace, resource);
export const locatedIn = api.locatedIn<LocatedInIDOnly, LocatedInFull, LocatedInDebug>(namespace, resource);
export const parentStructure = api.parentStructure<PSIDOnly, PSFull, PSDebug>(namespace, resource);
export const retrieve = api.retrieve<IDOnly, Full, Debug>(namespace, resource);
export const stats = api.stats<zone.Stats>(namespace, resource);
