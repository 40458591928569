import * as api from 'lib/go/utils';
import { container, figure_eight, route_node } from 'lib/types/go/telco';

const namespace = 'telco';
const resource = 'figure_eights';

type IDOnly = figure_eight.FigureEightIDOnly;
type Full = figure_eight.FigureEight;
type Debug = figure_eight.FigureEightDebug;

type CSIDOnly = figure_eight.ChildStructureIDOnly;
type CSFull = figure_eight.ChildStructure;
type CSDebug = figure_eight.ChildStructureDebug;

type LocatedInIDOnly = container.ContainerIDOnly | route_node.RouteNodeIDOnly;
type LocatedInFull = container.Container | route_node.RouteNode;
type LocatedInDebug = container.ContainerDebug | route_node.RouteNodeDebug;

type PSIDOnly = figure_eight.ParentStructureIDOnly;
type PSFull = figure_eight.ParentStructure;
type PSDebug = figure_eight.ParentStructureDebug;

export const childStructure = api.childStructure<CSIDOnly, CSFull, CSDebug>(namespace, resource);
export const has = api.has(namespace, resource);
export const locatedIn = api.locatedIn<LocatedInIDOnly, LocatedInFull, LocatedInDebug>(namespace, resource);
export const parentStructure = api.parentStructure<PSIDOnly, PSFull, PSDebug>(namespace, resource);
export const retrieve = api.retrieve<IDOnly, Full, Debug>(namespace, resource);
export const stats = api.stats<figure_eight.Stats>(namespace, resource);
