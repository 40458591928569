import axios, { AxiosError, AxiosResponse } from 'axios';
import { catchError, setSuccess } from 'lib/go/utils';
import { error as _error, request as _request } from 'lib/types/go';
import { Params } from 'lib/utils/http';

const error = (err: AxiosError) => catchError<_error.LogoutError>(err);
const success = (response: AxiosResponse) => setSuccess<undefined>(response);

export function logout(baseUrl: string, accessToken: string) {
  return (): _request.Request<undefined, _error.LogoutError> => {
    const source = axios.CancelToken.source();
    const cancelToken = source.token;
    const cancel = source.cancel;
    const params = new Params();
    params.set('access_token', accessToken);
    const url = `${baseUrl}/users/logout?${params.toString()}`;
    const request = axios.post(url, undefined, { cancelToken }).then(success).catch(error);
    return { cancel, request };
  };
}
