import { App } from 'app/components/App';
import * as React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

async function getRootDevComponent() {
  const hot = await import('react-hot-loader/root').then((rhl) => rhl.hot);
  // TODO: enable redux devtools once it supports newest version of react-redux
  // const DevTools = await import('app/components/DevTools').then((devToolsModule) => devToolsModule.default);
  class RootDev extends React.Component {
    render() {
      return (
        <Router>
          <React.Fragment>
            <App />
            {/* <DevTools /> */}
          </React.Fragment>
        </Router>
      );
    }
  };
  const HotRoot = hot(RootDev);
  return HotRoot;
}

async function getRootProdComponent() {
  // tslint:disable-next-line
  class RootProd extends React.Component {
    render() {
      return (
        <Router>
          <App />
        </Router>
      );
    }
  };
  return RootProd;
}

export async function configureRoot() {
  return IS_DEVELOPMENT ? getRootDevComponent() : getRootProdComponent();
}
