import { loadMapBackground, loadMapCustomLayers, loadMapGOLayers, loadMapGOLayersFilter } from 'app/utils/enums';
import {
  MapLayersBackgroundState,
  MapLayersCustomState,
  MapLayersGOFilterState,
  MapLayersGOState,
  MapLayersState
} from 'types';

const defaultMapBackground: MapLayersBackgroundState = {
  administration: false,
  matrikel: false,
  ortofoto: false,
  topo_fot: true,
  planned: false,
  cvf: false,
};

function getMapBackground(): MapLayersBackgroundState {
  let storedBackground: unknown;
  try {
    storedBackground = loadMapBackground();
  } catch {
    return defaultMapBackground;
  }
  if (storedBackground === null) {
    return defaultMapBackground;
  }
  if (typeof storedBackground === 'string') {
    switch (storedBackground) {
      case 'administration':
      case 'matrikel':
      case 'ortofoto':
      case 'topo_fot':
      case 'planned':
        return {
          ...defaultMapBackground,
          [storedBackground]: true,
        };
      default:
        return defaultMapBackground;
    }
  }
  return {
    ...defaultMapBackground,
    ...(typeof storedBackground === 'object' && storedBackground || {}),
  };
}

function getMapCustomLayers(): MapLayersCustomState {
  const custom: MapLayersCustomState = {};
  const { custom_layers } = FEATURES.map;
  if (custom_layers !== undefined) {
    custom_layers.forEach((layer) => custom[layer.key] = false);
  }
  const storedCustomLayers = loadMapCustomLayers();
  return {
    ...custom,
    ...(storedCustomLayers === null || typeof storedCustomLayers !== 'object' ? {} : storedCustomLayers),
  };
}

function getMapGOLayers(): MapLayersGOState {
  const storedGOLayers = loadMapGOLayers();
  const defaultState: MapLayersGOState = {
    access_address: false,
    building: false,
    conduit: false,
    conduit_adapter: false,
    container_hub: false,
    container_street_cabinet: false,
    container_underground_utility_box: false,
    customer: false,
    fiber_cable: false,
    figure_eight: false,
    logical_location: false,
    optical_path: false,
    optical_splice: false,
    route: false,
    sketch: false,
    zone: false,
  };
  return {
    ...defaultState,
    ...GO.layers,
    ...(storedGOLayers === null || typeof storedGOLayers !== 'object' ? {} : storedGOLayers),
    ...FEATURES.sketching && FEATURES.sketching.enabled
      ? null
      : { sketch: false },
  };
}

function getGOLayersFilter(): MapLayersGOFilterState {
  const storedFilter = loadMapGOLayersFilter();
  return {
    address_count: false,
    address_main: true,
    customer_active: true,
    customer_prospect: true,
    route_inService: true,
    route_outofService: true,
    route_planned: true,
    route_unknown: true,
    zone_container: true,
    zone_project: true,
    zone_sale: true,
    zone_stage: true,
    ...(storedFilter || {}),
  }
}

export function getMapLayers(): MapLayersState {
  return {
    background: getMapBackground(),
    custom: getMapCustomLayers(),
    go: getMapGOLayers(),
    goFilter: getGOLayersFilter(),
    goServiceInfo: {
      allLayers: [],
      symbolRules: {},
    },
    urlCacheBoost: Date.now(),
  };
}
