import axios, { AxiosError, AxiosResponse } from 'axios';
import { catchError, setSuccess } from 'lib/go/utils';
import { error as _error, request as _request, users } from 'lib/types/go';
import { Params } from 'lib/utils/http';

const error = (err: AxiosError) => catchError<_error.ObjectError>(err);
const success = (response: AxiosResponse) => setSuccess<users.User>(response);

export function retrieve(baseUrl: string, accessToken: string) {
  return (id: string): _request.Request<users.User, _error.ObjectError> => {
    const source = axios.CancelToken.source();
    const cancelToken = source.token;
    const cancel = source.cancel;
    const params = new Params();
    params.set('access_token', accessToken);
    const url = `${baseUrl}/users/${id}?${params.toString()}`;
    const headers = { 'Content-Type': 'application/json' };
    const request = axios.get(url, { cancelToken, headers }).then(success).catch(error);
    return { cancel, request };
  };
}
