import { getItem, getJson, setItem, setJson } from 'app/utils/storage';
import {
  Language,
  MapLayersBackgroundState,
  MapLayersCustomState,
  MapLayersGOFilterState,
  MapLayersGOState,
  PropertiesFilter
} from 'types';

const LANGUAGE_KEY = 'language';
const MAP_BACKGROUND_KEY = 'map-background';
const MAP_CUSTOM_LAYERS_KEY = 'map-custom-layers';
const MAP_GO_FILTERS_KEY = 'map-filters';
const MAP_GO_LAYERS_KEY = 'map-go-layers';
const MAP_GO_LAYERS_FILTER_KEY = 'map-go-layers-filter';
const PROPERTIES_FILTER_KEY = 'properties-filter';
const MAP_GOTO_ON_OPEN = 'map-goto-on-open-object';

export function loadLanguage(): Language {
  const value = getItem(LANGUAGE_KEY);
  switch (value) {
    case Language.Danish:
    case Language.English:
      return value;
    default:
      return Language.English;
  }
}

export function loadMapBackground(): unknown {
  return getJson(MAP_BACKGROUND_KEY);
}

export function loadMapCustomLayers() {
  return getJson<MapLayersCustomState>(MAP_CUSTOM_LAYERS_KEY);
}

export function loadMapGOLayers() {
  const layers = getJson<MapLayersGOState>(MAP_GO_LAYERS_KEY);
  if (layers === null) {
    // use filters for backward compability
    return getJson<MapLayersGOState>(MAP_GO_FILTERS_KEY);
  }
  return layers;
}

export function loadMapGOLayersFilter() {
  return getJson<MapLayersGOFilterState>(MAP_GO_LAYERS_FILTER_KEY);
}

export function loadPropertiesFilter(): PropertiesFilter {
  const value = getItem(PROPERTIES_FILTER_KEY);
  switch (value) {
    case PropertiesFilter.All:
    case PropertiesFilter.HideEmpty:
      return value;
    default:
      return PropertiesFilter.All;
  }
}

export function saveLanguage(language: Language) {
  setItem(LANGUAGE_KEY, language);
}

export function saveMapBackground(background: MapLayersBackgroundState) {
  setJson(MAP_BACKGROUND_KEY, background);
}

export function saveMapCustomLayers(layers: MapLayersCustomState) {
  setJson(MAP_CUSTOM_LAYERS_KEY, layers);
}

export function saveMapGOLayers(layers: MapLayersGOState) {
  setJson(MAP_GO_LAYERS_KEY, layers);
}

export function saveMapGOLayersFilter(filter: MapLayersGOFilterState) {
  setJson(MAP_GO_LAYERS_FILTER_KEY, filter);
}

export function savePropertiesFilter(propertiesFilter: PropertiesFilter) {
  setItem(PROPERTIES_FILTER_KEY, propertiesFilter);
}

export function saveMapGoto(enabled: boolean) {
  setItem(MAP_GOTO_ON_OPEN, JSON.stringify(enabled));
}
export function loadMapGoto(): boolean {
  const data = getItem(MAP_GOTO_ON_OPEN);
  if (!data) return true;

  try {
    const obj = JSON.parse(data);
    return typeof obj !== 'boolean' || obj;
  } catch (e) {
    return true;
  }
}
