// tslint:disable-next-line
import { StyledComponentProps } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
// tslint:disable-next-line
import * as React from 'react';

import { App } from './App';
import { _Styles } from './interfaces';

const styles: _Styles = (theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    height: '100vh',
    overflow: 'hidden',
    // fixed position ensures correct viewport on "Chrome on Android"
    position: 'fixed',
    width: '100%',
    zIndex: 1,
  },
});

const StyledApp = withStyles(styles)(App);
export default StyledApp;
