import axios, { AxiosError, AxiosResponse } from 'axios';
import { catchError, setSuccess } from 'lib/go/utils';
import { ObjectsWithinPolygonError } from 'lib/types/go/error';
import { Details } from 'lib/types/go/options';
import { Request } from 'lib/types/go/request';
import {
  ObjectsWithinPolygon,
  ObjectsWithinPolygonDebug,
  ObjectsWithinPolygonIDOnly,
  ObjectsWithinPolygonOptions
} from 'lib/types/go/telco/objects_within_polygon';
import { Params } from 'lib/utils/http';

type Error = ObjectsWithinPolygonError;
type IDOnly = ObjectsWithinPolygonIDOnly;
type Full = ObjectsWithinPolygon;
type Debug = ObjectsWithinPolygonDebug;

const error = (err: AxiosError) => catchError<Error>(err);
const success = (response: AxiosResponse) => setSuccess<any>(response);

function getParams(accessToken: string, opts: ObjectsWithinPolygonOptions<Details>): string {
  const params = new Params();
  params.set('details', opts.details === undefined ? 'full' : opts.details);
  params.set('access_token', accessToken);
  if (opts.date !== undefined) { params.set('date', opts.date); }
  if (opts.scope !== undefined) { params.set('scope', opts.scope.join(',')); }
  if (opts.output_geoms !== undefined) { params.set('output_geoms', opts.output_geoms.toString()); }
  if (opts.strictly_inside !== undefined) { params.set('strictly_inside', opts.strictly_inside.toString()); }
  if (opts.version !== undefined) { params.set('version', opts.version); }
  if (opts.version_only !== undefined) { params.set('version_only', opts.version_only.toString()); }
  return params.toString();
}

export function objectsWithinPolygon(baseUrl: string, accessToken: string) {
  function makeRequest(opts: ObjectsWithinPolygonOptions<'id_only'>): Request<IDOnly, Error>;
  function makeRequest(opts: ObjectsWithinPolygonOptions<'full'>): Request<Full, Error>;
  function makeRequest(opts: ObjectsWithinPolygonOptions<'debug'>): Request<Debug, Error>;
  function makeRequest(opts: ObjectsWithinPolygonOptions<Details>) {
    const source = axios.CancelToken.source();
    const cancelToken = source.token;
    const cancel = source.cancel;
    const params = getParams(accessToken, opts);
    const url = `${baseUrl}/telco/objects_within_polygon?${params.toString()}`;
    const headers = { 'Content-Type': 'application/json' };
    const request = axios.post(url, opts.polygon, { cancelToken, headers }).then(success).catch(error);
    return { cancel, request };
  }
  return makeRequest;
}
