import * as api from 'lib/go/utils';
import { container, optical_splice, splitter } from 'lib/types/go/telco';

const namespace = 'telco';
const resource = 'splitters';

type IDOnly = splitter.SplitterIDOnly;
type Full = splitter.Splitter;
type Debug = splitter.SplitterDebug;

type ConnectionIDOnly = splitter.ConnectionIDOnly;
type ConnectionFull = splitter.Connection;
type ConnectionDebug = splitter.Connection;

type CSIDOnly = splitter.ChildStructureIDOnly;
type CSFull = splitter.ChildStructure;
type CSDebug = splitter.ChildStructureDebug;

type LocatedInIDOnly = container.ContainerIDOnly | optical_splice.OpticalSpliceIDOnly;
type LocatedInFull = container.Container | optical_splice.OpticalSplice;
type LocatedInDebug = container.ContainerDebug | optical_splice.OpticalSpliceDebug;

type PSIDOnly = splitter.ParentStructureIDOnly;
type PSFull = splitter.ParentStructure;
type PSDebug = splitter.ParentStructureDebug;

export const childStructure = api.childStructure<CSIDOnly, CSFull, CSDebug>(namespace, resource);
export const connection = api.connection<ConnectionIDOnly, ConnectionFull, ConnectionDebug>(namespace, resource);
export const locatedIn = api.locatedIn<LocatedInIDOnly, LocatedInFull, LocatedInDebug>(namespace, resource);
export const parentStructure = api.parentStructure<PSIDOnly, PSFull, PSDebug>(namespace, resource);
export const retrieve = api.retrieve<IDOnly, Full, Debug>(namespace, resource);
export const stats = api.stats<splitter.Stats>(namespace, resource);
