import * as React from 'react';
import { FiberCable } from 'gridtools/types/go/telco/fiber_cable';

import {
  FiberCableHeader, FiberCableObject, FiberCableStructure,
  ReportOptions, ReportProps, ReportUrlParams
} from './types';
import { ApiHelper } from './api';
import { formatNumber, getTotalLength, prepareStruct } from './utils';
import Report from './Report';
import ReportLoaderBase, { withReportParams } from './ReportLoaderBase';

const BLAESEPLAN = 'Blæseplan';

const createReportProps = (opt: ReportOptions,
                           head: FiberCableHeader | undefined,
                           data: FiberCableStructure | undefined): ReportProps => {

  const prepared = data ? prepareStruct(opt.id, data) : [];
  const items = prepared.map((it) => ({
    ...it,
    below: it.getLineText(it.length),
  }));

  const headLength = head && head.geographical_length_m;
  const actualLength = getTotalLength(prepared);
  if (IS_DEVELOPMENT)
    console.log(`Totallængde = ${headLength}; total length = ${actualLength}`);

  const headers = [ BLAESEPLAN.toLocaleUpperCase() ];
  if (head) {
    const {
      identification,
      specification,
      number_of_fibers: fibers,
      geographical_length_m: length
    } = head;

    const name = [identification, specification]
      .filter(s => s)
      .join(' - ');

    headers.push(
      `Fiberkabel.: ${name} (${fibers} fiber)`,
      `Totallængde.: ${formatNumber(length || 0)} m`);
  }

  return {
    headers,
    items,
    footer: {
      ...opt,
      name: head && head.identification || '',
      objectTypeName: 'Kabel',
      notes: head && head.comment
    }
  };
};

export const createPdfReport = (cable: FiberCable,
                                opt: ReportUrlParams) => {
  const report = new ReportLoader(opt);
  return report
    .load(cable.details)
    .then(data => report.createPdf(data));
};

type LoaderData = { head: FiberCableHeader, data: FiberCableStructure };

class ReportLoader extends ReportLoaderBase<LoaderData> {

  private readonly api: ApiHelper;

  constructor(props: ReportUrlParams) {
    super(props, BLAESEPLAN);

    const { api, token } = this.options;
    this.api = new ApiHelper(token, api);
  }

  async load(cable?: FiberCableHeader) {
    const { id } = this.options;
    const [ head, data ] = await Promise.all([
      cable
        ? Promise.resolve(cable)
        : this.api.fetchHeader(id),
      this.api.fetchStructure(id),
      //delay(5000)
    ]);
    return {
      head,
      data: {
        objects: data.objects as any as FiberCableObject[],
        lines: data.lines
      }
    };
  }

  protected loadData() {
    return this.load();
  }

  protected renderReport({ head, data }: LoaderData) {
    const props = createReportProps(this.options, head, data);

    return <div>
      {this.renderPdfButton()}

      <br />

      <Report {...props} {...this.pdf.reportProps} />
    </div>;
  }
}

export default withReportParams()(ReportLoader);

