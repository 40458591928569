import * as api from 'lib/go/utils';
import { optical_splice } from 'lib/types/go/telco';

const namespace = 'telco';
const resource = 'optical_splices';

type IDOnly = optical_splice.OpticalSpliceIDOnly;
type Full = optical_splice.OpticalSplice;
type Debug = optical_splice.OpticalSpliceDebug;

type ConnectionIDOnly = optical_splice.ConnectionIDOnly;
type ConnectionFull = optical_splice.Connection;
type ConnectionDebug = optical_splice.Connection;

type CSIDOnly = optical_splice.ChildStructureIDOnly;
type CSFull = optical_splice.ChildStructure;
type CSDebug = optical_splice.ChildStructureDebug;

type PSIDOnly = optical_splice.ParentStructureIDOnly;
type PSFull = optical_splice.ParentStructure;
type PSDebug = optical_splice.ParentStructureDebug;

export const childStructure = api.childStructure<CSIDOnly, CSFull, CSDebug>(namespace, resource);
export const connection = api.connection<ConnectionIDOnly, ConnectionFull, ConnectionDebug>(namespace, resource);
export const has = api.has(namespace, resource);
export const parentStructure = api.parentStructure<PSIDOnly, PSFull, PSDebug>(namespace, resource);
export const retrieve = api.retrieve<IDOnly, Full, Debug>(namespace, resource);
export const stats = api.stats<optical_splice.Stats>(namespace, resource);
