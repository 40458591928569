import * as api from 'lib/go/utils';
import { building_floor } from 'lib/types/go/telco';

const namespace = 'telco';
const resource = 'building_floors';

type IDOnly = building_floor.BuildingFloorIDOnly;
type Full = building_floor.BuildingFloor;
type Debug = building_floor.BuildingFloorDebug;

type CSIDOnly = building_floor.ChildStructureIDOnly;
type CSFull = building_floor.ChildStructure;
type CSDebug = building_floor.ChildStructureDebug;

type PSIDOnly = building_floor.ParentStructureIDOnly;
type PSFull = building_floor.ParentStructure;
type PSDebug = building_floor.ParentStructureDebug;

export const childStructure = api.childStructure<CSIDOnly, CSFull, CSDebug>(namespace, resource);
export const has = api.has(namespace, resource);
export const parentStructure = api.parentStructure<PSIDOnly, PSFull, PSDebug>(namespace, resource);
export const retrieve = api.retrieve<IDOnly, Full, Debug>(namespace, resource);
export const stats = api.stats<building_floor.Stats>(namespace, resource);
