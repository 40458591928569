import { withStyles } from '@material-ui/core';
import { analytics } from 'app/containers/Analytics';
import { setState } from 'app/reducers/loading';
import { login } from 'app/sagas/auth';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { StoreState } from 'types';

import { Login } from './Login';
import { _DispatchProps, _StateProps, _Styles } from './types';

const styles: _Styles = () => ({
  about: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    textAlign: 'center'
  }
});

const stateToProps = (state: StoreState): _StateProps => ({
  response: state.auth.loginResponse,
});

const dispatchToProps = (dispatch: Dispatch): _DispatchProps => ({
  onLoad: () => {
    const action = setState({ isLoading: false });
    dispatch(action);
  },
  onLogin: (username, password, remember) => {
    const action = login(username, password, remember);
    dispatch(action);
  },
});

const Analytics = analytics(Login);
const Styled = withStyles(styles)(Analytics);
const ConnectedLogin = connect(stateToProps, dispatchToProps)(Styled);
export default ConnectedLogin;
