import { isMoment, Moment } from 'moment';
import * as intl from 'react-intl-universal';

export const formatDate = (value: Date | Moment, short = false) => {
  if (isMoment(value)) value = value.toDate();

  const pad = (n: number) => n < 10 ? `0${n}` : `${n}`;
  const values = {
    value,
    D: pad(value.getDate()),
    M: pad(value.getMonth() + 1),
    Y: value.getFullYear(),
    h: pad(value.getHours()),
    m: pad(value.getMinutes()),
    s: pad(value.getSeconds()),
    __keepValues: true
  };
  return short
    ? intl.get('formats.date_short', values)
    : intl.get('formats.date', values);
};
