import { Theme } from '@material-ui/core';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

export const theme: Theme = createMuiTheme({
  palette: {
    primary: {
      contrastText: '#ffffff',
      dark: '#0069c0',
      light: '#64b5f6',
      main: '#2196f3',
    },
    secondary: {
      contrastText: '#000000',
      dark: '#c56200',
      light: '#ffc246',
      main: '#ff9100',
    },
  },
  typography: {
    useNextVariants: true,
  },
});

if (IS_DEVELOPMENT) console.log(theme);
