import * as api from 'lib/go/utils';
import { conduit_node } from 'lib/types/go/telco';

const namespace = 'telco';
const resource = 'conduit_nodes';

type IDOnly = conduit_node.ConduitNodeIDOnly;
type Full = conduit_node.ConduitNode;
type Debug = conduit_node.ConduitNodeDebug;

type CSIDOnly = conduit_node.ChildStructureIDOnly;
type CSFull = conduit_node.ChildStructure;
type CSDebug = conduit_node.ChildStructureDebug;

type PSIDOnly = conduit_node.ParentStructureIDOnly;
type PSFull = conduit_node.ParentStructure;
type PSDebug = conduit_node.ParentStructureDebug;

type TOIDOnly = conduit_node.TraceOutIDOnly;
type TOFull = conduit_node.TraceOut;
type TODebug = conduit_node.TraceOutDebug;

export const childStructure = api.childStructure<CSIDOnly, CSFull, CSDebug>(namespace, resource);
export const has = api.has(namespace, resource);
export const parentStructure = api.parentStructure<PSIDOnly, PSFull, PSDebug>(namespace, resource);
export const retrieve = api.retrieve<IDOnly, Full, Debug>(namespace, resource);
export const stats = api.stats<conduit_node.Stats>(namespace, resource);
export const traceOut = api.traceOut<TOIDOnly, TOFull, TODebug>(namespace, resource);
