import { GOProperties } from 'types';

export function getGOProperties(): GOProperties {
  const properties = GO.properties;
  return {
    dk_access_address: {
      address_name: true,
      cadastral_number: true,
      esr_property_code: true,
      house_number: true,
      kvh: true,
      municipality_code: true,
      municipality_name: true,
      postal_code: true,
      postal_district: true,
      region_code: true,
      region_name: true,
      road_code: true,
      road_name: true,
      ...(properties === undefined ? undefined : properties.dk_access_address),
    },
    dk_municipality: {
      municipality_code: true,
      municipality_name: true,
      region: true,
      ...(properties === undefined ? undefined : properties.dk_municipality),
    },
    dk_road: {
      municipality_code: true,
      road_code: true,
      road_name: true,
      ...(properties === undefined ? undefined : properties.dk_road),
    },
    dk_specific_address: {
      address_name: true,
      cadastral_number: true,
      door: true,
      esr_property_code: true,
      floor: true,
      house_number: true,
      kvhx: true,
      municipality_code: true,
      municipality_name: true,
      postal_code: true,
      postal_district: true,
      region_code: true,
      region_name: true,
      road_code: true,
      road_name: true,
      usage: true,
      ...(properties === undefined ? undefined : properties.dk_specific_address),
    },
    go_document: {
      comment: true,
      custom_data: (
        properties && properties.go_document && properties.go_document.custom_data || {}
      ),
      file_name: true,
      identification: true,
      size: true,
      type: true,
      ...(properties === undefined ? undefined : properties.go_document),
    },
    telco_building: {
      access_description: true,
      canonical_id: true,
      comment: true,
      custom_data: (
        properties === undefined || properties.telco_building === undefined || properties.telco_building.custom_data === undefined
          ? {}
          : properties.telco_building.custom_data
      ),
      identification: true,
      op_status: true,
      owner: true,
      usage: true,
      ...(properties === undefined ? undefined : properties.telco_building),
    },
    telco_building_floor: {
      comment: true,
      custom_data: (
        properties === undefined || properties.telco_building_floor === undefined || properties.telco_building_floor.custom_data === undefined
          ? {}
          : properties.telco_building_floor.custom_data
      ),
      identification: true,
      op_status: true,
      owner: true,
      type: true,
      ...(properties === undefined ? undefined : properties.telco_building_floor),
    },
    telco_building_unit: {
      access_description: true,
      comment: true,
      custom_data: (
        properties === undefined || properties.telco_building_unit === undefined || properties.telco_building_unit.custom_data === undefined
          ? {}
          : properties.telco_building_unit.custom_data
      ),
      identification: true,
      op_status: true,
      owner: true,
      type: true,
      usage: true,
      ...properties && properties.telco_building_unit
    },
    telco_conduit: {
      c_inner_diameter_mm: true,
      c_outer_diameter_mm: true,
      comment: true,
      construction: true,
      custom_data: (
          properties === undefined || properties.telco_conduit === undefined || properties.telco_conduit.custom_data === undefined
        ? {}
        : properties.telco_conduit.custom_data
      ),
      f_height_mm: true,
      f_width_mm: true,
      fault_state: true,
      geographical_length_m: true,
      identification: true,
      item_number: true,
      leased_from: true,
      manufacturer: true,
      material: true,
      op_status: true,
      owner: true,
      primary_color: true,
      specification: true,
      type: true,
      used_by: true,
      ...(properties === undefined ? undefined : properties.telco_conduit),
    },
    telco_conduit_adapter: {
      comment: true,
      custom_data: (
             properties === undefined
          || properties.telco_conduit_adapter === undefined
          || properties.telco_conduit_adapter.custom_data === undefined
        ? {}
        : properties.telco_conduit_adapter.custom_data
      ),
      identification: true,
      item_number: true,
      manufacturer: true,
      material: true,
      number_of_holes: true,
      op_status: true,
      owner: true,
      specification: true,
      type: true,
      ...(properties === undefined ? undefined : properties.telco_conduit_adapter),
    },
    telco_container: {
      comment: true,
      custom_data: (
          properties === undefined || properties.telco_container === undefined || properties.telco_container.custom_data === undefined
        ? {}
        : properties.telco_container.custom_data
      ),
      dimension: true,
      identification: true,
      item_number: true,
      manufacturer: true,
      op_status: true,
      owner: true,
      specification: true,
      ...(properties === undefined ? undefined : properties.telco_container),
    },
    telco_customer: {
      address_description: true,
      comment: true,
      conduit_connectivity_info: true,
      custom_data: (
          properties === undefined || properties.telco_customer === undefined || properties.telco_customer.custom_data === undefined
        ? {}
        : properties.telco_customer.custom_data
      ),
      customer_status: true,
      customer_type: true,
      identification: true,
      op_status: true,
      owner: true,
      ...(properties === undefined ? undefined : properties.telco_customer),
    },
    telco_fiber: {
      color: true,
      comment: true,
      custom_data: (
          properties === undefined || properties.telco_fiber === undefined || properties.telco_fiber.custom_data === undefined
        ? {}
        : properties.telco_fiber.custom_data
      ),
      fault_state: true,
      identification: true,
      op_status: true,
      owner: true,
      ...(properties === undefined ? undefined : properties.telco_fiber),
    },
    telco_fiber_bundle: {
      color: true,
      comment: true,
      custom_data: (
          properties === undefined || properties.telco_fiber_bundle === undefined || properties.telco_fiber_bundle.custom_data === undefined
        ? {}
        : properties.telco_fiber_bundle.custom_data
      ),
      identification: true,
      number_of_fibers: true,
      op_status: true,
      owner: true,
      ...(properties === undefined ? undefined : properties.telco_fiber_bundle),
    },
    telco_fiber_cable: {
      comment: true,
      custom_data: (
          properties === undefined || properties.telco_fiber_cable === undefined || properties.telco_fiber_cable.custom_data === undefined
        ? {}
        : properties.telco_fiber_cable.custom_data
      ),
      geographical_length_m: true,
      identification: true,
      item_number: true,
      leased_from: true,
      manufacturer: true,
      measured_length_m: true,
      number_of_bundles: true,
      number_of_fibers: true,
      op_status: true,
      outer_diameter_mm: true,
      owner: true,
      specification: true,
      type: true,
      used_by: true,
      ...(properties === undefined ? undefined : properties.telco_fiber_cable),
    },
    telco_fiber_port: {
      comment: true,
      connector_type: true,
      custom_data: (
          properties === undefined || properties.telco_fiber_port === undefined || properties.telco_fiber_port.custom_data === undefined
        ? {}
        : properties.telco_fiber_port.custom_data
      ),
      fault_state: true,
      identification: true,
      op_status: true,
      owner: true,
      port_location: true,
      signal_type: true,
      specification: true,
      type: true,
      wavelength_nm_lower: true,
      wavelength_nm_upper: true,
      ...(properties === undefined ? undefined : properties.telco_fiber_port),
    },
    telco_figure_eight: {
      comment: true,
      construction: true,
      custom_data: (
          properties === undefined || properties.telco_figure_eight === undefined || properties.telco_figure_eight.custom_data === undefined
        ? {}
        : properties.telco_figure_eight.custom_data
      ),
      identification: true,
      length_mm: true,
      op_status: true,
      owner: true,
      type: true,
      ...(properties === undefined ? undefined : properties.telco_figure_eight),
    },
    telco_logical_location: {
      comment: true,
      custom_data: (
        properties === undefined || properties.telco_logical_location === undefined || properties.telco_logical_location.custom_data === undefined
          ? {}
          : properties.telco_logical_location.custom_data
      ),
      identification: true,
      op_status: true,
      owner: true,
      type: true,
      ...(properties === undefined ? undefined : properties.telco_logical_location),
    },
    telco_optical_path: {
      comment: true,
      custom_data: (
          properties === undefined || properties.telco_optical_path === undefined || properties.telco_optical_path.custom_data === undefined
        ? {}
        : properties.telco_optical_path.custom_data
      ),
      identification: true,
      number_of_fibers: true,
      op_status: true,
      owner: true,
      type: true,
      wave_length_nm: true,
      ...(properties === undefined ? undefined : properties.telco_optical_path),
    },
    telco_optical_splice: {
      comment: true,
      custom_data: (
             properties === undefined
          || properties.telco_optical_splice === undefined
          || properties.telco_optical_splice.custom_data === undefined
        ? {}
        : properties.telco_optical_splice.custom_data
      ),
      identification: true,
      max_number_of_trays: true,
      number_of_cable_holes: true,
      number_of_mounting_sides: true,
      op_status: true,
      owner: true,
      specification: true,
      tray_capacity: true,
      ...(properties === undefined ? undefined : properties.telco_optical_splice),
    },
    telco_rack_equipment: {
      Function: true,
      comment: true,
      custom_data: (
             properties === undefined
          || properties.telco_rack_equipment === undefined
          || properties.telco_rack_equipment.custom_data === undefined
        ? {}
        : properties.telco_rack_equipment.custom_data
      ),
      dimension: true,
      identification: true,
      item_number: true,
      manufacturer: true,
      op_status: true,
      owner: true,
      specification: true,
      type: true,
      ...(properties === undefined ? undefined : properties.telco_rack_equipment),
    },
    telco_route: {
      comment: true,
      construction: true,
      custom_data: (
          properties === undefined || properties.telco_route === undefined || properties.telco_route.custom_data === undefined
        ? {}
        : properties.telco_route.custom_data
      ),
      geographical_length_m: true,
      location_verified: true,
      op_status: true,
      owner: true,
      type: true,
      ...(properties === undefined ? undefined : properties.telco_route),
    },
    telco_splice_tray: {
      comment: true,
      custom_data: (
          properties === undefined || properties.telco_splice_tray === undefined || properties.telco_splice_tray.custom_data === undefined
        ? {}
        : properties.telco_splice_tray.custom_data
      ),
      identification: true,
      mounting_position: true,
      mounting_side: true,
      number_of_mounting_positions: true,
      op_status: true,
      owner: true,
      specification: true,
      ...(properties === undefined ? undefined : properties.telco_splice_tray),
    },
    telco_splitter: {
      comment: true,
      custom_data: (
          properties === undefined || properties.telco_splitter === undefined || properties.telco_splitter.custom_data === undefined
        ? {}
        : properties.telco_splitter.custom_data
      ),
      function: true,
      identification: true,
      item_number: true,
      manufacturer: true,
      op_status: true,
      owner: true,
      signal_degration: true,
      splitter_ratio: true,
      type: true,
      ...(properties === undefined ? undefined : properties.telco_splitter),
    },
    telco_zone: {
      comment: true,
      custom_data: (
          properties === undefined || properties.telco_zone === undefined || properties.telco_zone.custom_data === undefined
        ? {}
        : properties.telco_zone.custom_data
      ),
      identification: true,
      op_status: true,
      owner: true,
      project: true,
      status: true,
      type: true,
      ...(properties === undefined ? undefined : properties.telco_zone),
    },
  };
}
