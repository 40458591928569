import * as api from 'lib/go/utils';
import { conduit } from 'lib/types/go/telco';

const namespace = 'telco';
const resource = 'conduits';

type IDOnly = conduit.ConduitIDOnly;
type Full = conduit.Conduit;
type Debug = conduit.ConduitDebug;

type CSIDOnly = conduit.ChildStructureIDOnly;
type CSFull = conduit.ChildStructure;
type CSDebug = conduit.ChildStructureDebug;

type PSIDOnly = conduit.ParentStructureIDOnly;
type PSFull = conduit.ParentStructure;
type PSDebug = conduit.ParentStructureDebug;

export const childStructure = api.childStructure<CSIDOnly, CSFull, CSDebug>(namespace, resource);
export const has = api.has(namespace, resource);
export const parentStructure = api.parentStructure<PSIDOnly, PSFull, PSDebug>(namespace, resource);
export const retrieve = api.retrieve<IDOnly, Full, Debug>(namespace, resource);
export const stats = api.stats<conduit.Stats>(namespace, resource);

export function primaryColor(color: conduit.ConduitPrimaryColor, defaultColor: string = 'rgb(224, 224, 224)'): string {
  switch (color) {
    case 'AliceBlue': return '#F0F8FF';
    case 'AntiqueWhite': return '#FAEBD7';
    case 'Aqua': return '#00FFFF';
    case 'Aquamarine': return '#7FFFD4';
    case 'Azure': return '#F0FFFF';
    case 'Beige': return '#F5F5DC';
    case 'Bisque': return '#FFE4C4';
    case 'Black': return '#000000';
    case 'BlanchedAlmond': return '#FFEBCD';
    case 'Blue': return '#0000FF';
    case 'BlueViolet': return '#8A2BE2';
    case 'Brown': return '#A52A2A';
    case 'BurlyWood': return '#DEB887';
    case 'CadetBlue': return '#5F9EA0';
    case 'Chartreuse': return '#7FFF00';
    case 'Chocolate': return '#D2691E';
    case 'Coral': return '#FF7F50';
    case 'CornflowerBlue': return '#6495ED';
    case 'Cornsilk': return '#FFF8DC';
    case 'Crimson': return '#DC143C';
    case 'Cyan': return '#00FFFF';
    case 'DarkBlue': return '#00008B';
    case 'DarkCyan': return '#008B8B';
    case 'DarkGoldenRod': return '#B8860B';
    case 'DarkGray': return '#A9A9A9';
    case 'DarkGrey': return '#A9A9A9';
    case 'DarkGreen': return '#006400';
    case 'DarkKhaki': return '#BDB76B';
    case 'DarkMagenta': return '#8B008B';
    case 'DarkOliveGreen': return '#556B2F';
    case 'DarkOrange': return '#FF8C00';
    case 'DarkOrchid': return '#9932CC';
    case 'DarkRed': return '#8B0000';
    case 'DarkSalmon': return '#E9967A';
    case 'DarkSeaGreen': return '#8FBC8F';
    case 'DarkSlateBlue': return '#483D8B';
    case 'DarkSlateGray': return '#2F4F4F';
    case 'DarkSlateGrey': return '#2F4F4F';
    case 'DarkTurquoise': return '#00CED1';
    case 'DarkViolet': return '#9400D3';
    case 'DeepPink': return '#FF1493';
    case 'DeepSkyBlue': return '#00BFFF';
    case 'DimGray': return '#696969';
    case 'DimGrey': return '#696969';
    case 'DodgerBlue': return '#1E90FF';
    case 'FireBrick': return '#B22222';
    case 'FloralWhite': return '#FFFAF0';
    case 'ForestGreen': return '#228B22';
    case 'Fuchsia': return '#FF00FF';
    case 'Gainsboro': return '#DCDCDC';
    case 'GhostWhite': return '#F8F8FF';
    case 'Gold': return '#FFD700';
    case 'GoldenRod': return '#DAA520';
    case 'Gray': return '#808080';
    case 'Grey': return '#808080';
    case 'Green': return '#008000';
    case 'GreenYellow': return '#ADFF2F';
    case 'HoneyDew': return '#F0FFF0';
    case 'HotPink': return '#FF69B4';
    case 'IndianRed ': return '#CD5C5C';
    case 'Indigo ': return '#4B0082';
    case 'Ivory': return '#FFFFF0';
    case 'Khaki': return '#F0E68C';
    case 'Lavender': return '#E6E6FA';
    case 'LavenderBlush': return '#FFF0F5';
    case 'LawnGreen': return '#7CFC00';
    case 'LemonChiffon': return '#FFFACD';
    case 'LightBlue': return '#ADD8E6';
    case 'LightCoral': return '#F08080';
    case 'LightCyan': return '#E0FFFF';
    case 'LightGoldenRodYellow': return '#FAFAD2';
    case 'LightGray': return '#D3D3D3';
    case 'LightGrey': return '#D3D3D3';
    case 'LightGreen': return '#90EE90';
    case 'LightPink': return '#FFB6C1';
    case 'LightSalmon': return '#FFA07A';
    case 'LightSeaGreen': return '#20B2AA';
    case 'LightSkyBlue': return '#87CEFA';
    case 'LightSlateGray': return '#778899';
    case 'LightSlateGrey': return '#778899';
    case 'LightSteelBlue': return '#B0C4DE';
    case 'LightYellow': return '#FFFFE0';
    case 'Lime': return '#00FF00';
    case 'LimeGreen': return '#32CD32';
    case 'Linen': return '#FAF0E6';
    case 'Magenta': return '#FF00FF';
    case 'Maroon': return '#800000';
    case 'MediumAquaMarine': return '#66CDAA';
    case 'MediumBlue': return '#0000CD';
    case 'MediumOrchid': return '#BA55D3';
    case 'MediumPurple': return '#9370DB';
    case 'MediumSeaGreen': return '#3CB371';
    case 'MediumSlateBlue': return '#7B68EE';
    case 'MediumSpringGreen': return '#00FA9A';
    case 'MediumTurquoise': return '#48D1CC';
    case 'MediumVioletRed': return '#C71585';
    case 'MidnightBlue': return '#191970';
    case 'MintCream': return '#F5FFFA';
    case 'MistyRose': return '#FFE4E1';
    case 'Moccasin': return '#FFE4B5';
    case 'NavajoWhite': return '#FFDEAD';
    case 'Navy': return '#000080';
    case 'OldLace': return '#FDF5E6';
    case 'Olive': return '#808000';
    case 'OliveDrab': return '#6B8E23';
    case 'Orange': return '#FFA500';
    case 'OrangeRed': return '#FF4500';
    case 'Orchid': return '#DA70D6';
    case 'PaleGoldenRod': return '#EEE8AA';
    case 'PaleGreen': return '#98FB98';
    case 'PaleTurquoise': return '#AFEEEE';
    case 'PaleVioletRed': return '#DB7093';
    case 'PapayaWhip': return '#FFEFD5';
    case 'PeachPuff': return '#FFDAB9';
    case 'Peru': return '#CD853F';
    case 'Pink': return '#FFC0CB';
    case 'Plum': return '#DDA0DD';
    case 'PowderBlue': return '#B0E0E6';
    case 'Purple': return '#800080';
    case 'RebeccaPurple': return '#663399';
    case 'Red': return '#FF0000';
    case 'RosyBrown': return '#BC8F8F';
    case 'RoyalBlue': return '#4169E1';
    case 'SaddleBrown': return '#8B4513';
    case 'Salmon': return '#FA8072';
    case 'SandyBrown': return '#F4A460';
    case 'SeaGreen': return '#2E8B57';
    case 'SeaShell': return '#FFF5EE';
    case 'Sienna': return '#A0522D';
    case 'Silver': return '#C0C0C0';
    case 'SkyBlue': return '#87CEEB';
    case 'SlateBlue': return '#6A5ACD';
    case 'SlateGray': return '#708090';
    case 'SlateGrey': return '#708090';
    case 'Snow': return '#FFFAFA';
    case 'SpringGreen': return '#00FF7F';
    case 'SteelBlue': return '#4682B4';
    case 'Tan': return '#D2B48C';
    case 'Teal': return '#008080';
    case 'Thistle': return '#D8BFD8';
    case 'Tomato': return '#FF6347';
    case 'Turquoise': return '#40E0D0';
    case 'Violet': return '#EE82EE';
    case 'Wheat': return '#F5DEB3';
    case 'White': return '#FFFFFF';
    case 'WhiteSmoke': return '#F5F5F5';
    case 'Yellow': return '#FFFF00';
    case 'YellowGreen': return '#9ACD32';
    default: return defaultColor;
  }
}
