import * as api from 'lib/go/utils';
import { container, route_node } from 'lib/types/go/telco';

const namespace = 'telco';
const resource = 'containers';

type IDOnly = container.ContainerIDOnly;
type Full = container.Container;
type Debug = container.ContainerDebug;

type ConnectionIDOnly = container.ConnectionIDOnly;
type ConnectionFull = container.Connection;
type ConnectionDebug = container.Connection;

type CSIDOnly = container.ChildStructureIDOnly;
type CSFull = container.ChildStructure;
type CSDebug = container.ChildStructureDebug;

type LocatedInIDOnly = route_node.RouteNodeIDOnly;
type LocatedInFull = route_node.RouteNode;
type LocatedInDebug = route_node.RouteNodeDebug;

type PSIDOnly = container.ParentStructureIDOnly;
type PSFull = container.ParentStructure;
type PSDebug = container.ParentStructureDebug;

export const childStructure = api.childStructure<CSIDOnly, CSFull, CSDebug>(namespace, resource);
export const connection = api.connection<ConnectionIDOnly, ConnectionFull, ConnectionDebug>(namespace, resource);
export const has = api.has(namespace, resource);
export const locatedIn = api.locatedIn<LocatedInIDOnly, LocatedInFull, LocatedInDebug>(namespace, resource);
export const parentStructure = api.parentStructure<PSIDOnly, PSFull, PSDebug>(namespace, resource);
export const retrieve = api.retrieve<IDOnly, Full, Debug>(namespace, resource);
export const stats = api.stats<container.Stats>(namespace, resource);
