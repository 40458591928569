import * as api from 'lib/go/utils';
import { rack_equipment } from 'lib/types/go/telco';

const namespace = 'telco';
const resource = 'rack_equipments';

type IDOnly = rack_equipment.RackEquipmentIDOnly;
type Full = rack_equipment.RackEquipment;
type Debug = rack_equipment.RackEquipmentDebug;

type ConnectionIDOnly = rack_equipment.ConnectionIDOnly;
type ConnectionFull = rack_equipment.Connection;
type ConnectionDebug = rack_equipment.Connection;

type CSIDOnly = rack_equipment.ChildStructureIDOnly;
type CSFull = rack_equipment.ChildStructure;
type CSDebug = rack_equipment.ChildStructureDebug;

type PSIDOnly = rack_equipment.ParentStructureIDOnly;
type PSFull = rack_equipment.ParentStructure;
type PSDebug = rack_equipment.ParentStructureDebug;

export const childStructure = api.childStructure<CSIDOnly, CSFull, CSDebug>(namespace, resource);
export const connection = api.connection<ConnectionIDOnly, ConnectionFull, ConnectionDebug>(namespace, resource);
export const parentStructure = api.parentStructure<PSIDOnly, PSFull, PSDebug>(namespace, resource);
export const retrieve = api.retrieve<IDOnly, Full, Debug>(namespace, resource);
export const stats = api.stats<rack_equipment.Stats>(namespace, resource);
