import { samlRequest as samlRequestAPI } from 'app/api/saml/request';
import * as loadingActions from 'app/reducers/loading';
import { utils } from 'gridtools/types';
import { call, put, takeLatest } from 'redux-saga/effects';

const SAML_REQUEST = 'sagas/saml/request';

export type SAMLRequestResponse = utils.UnwrapPromise<ReturnType<typeof samlRequestAPI>['request']>;

export type SAMLRequestAction = ReturnType<typeof samlRequest>;

export function samlRequest() {
  return {
    type: SAML_REQUEST as typeof SAML_REQUEST,
  };
}

export function getSamlUrl() {
  return samlRequestAPI().request;
}

function* samlRequestSaga(action: SAMLRequestAction) {
  yield put(loadingActions.startSamlRequest());
  try {
    const response: SAMLRequestResponse = yield call(getSamlUrl);
    const samlRequest = response.data.request_url;
    window.location.replace(samlRequest);
  } catch {
    yield put(loadingActions.finishSamlRequest());
  }
}

export function* samlSaga() {
  yield takeLatest(SAML_REQUEST, samlRequestSaga);
}
