import * as api from 'lib/go/utils';
import { fiber_port, rack_equipment } from 'lib/types/go/telco';

const namespace = 'telco';
const resource = 'fiber_ports';

type IDOnly = fiber_port.FiberPortIDOnly;
type Full = fiber_port.FiberPort;
type Debug = fiber_port.FiberPortDebug;

type ConnectionIDOnly = fiber_port.ConnectionIDOnly;
type ConnectionFull = fiber_port.Connection;
type ConnectionDebug = fiber_port.Connection;

type CSIDOnly = fiber_port.ChildStructureIDOnly;
type CSFull = fiber_port.ChildStructure;
type CSDebug = fiber_port.ChildStructureDebug;

type LocatedInIDOnly = rack_equipment.RackEquipmentIDOnly;
type LocatedInFull = rack_equipment.RackEquipment;
type LocatedInDebug = rack_equipment.RackEquipmentDebug;

type PSIDOnly = fiber_port.ParentStructureIDOnly;
type PSFull = fiber_port.ParentStructure;
type PSDebug = fiber_port.ParentStructureDebug;

type TOIDOnly = fiber_port.TraceOutIDOnly;
type TOFull = fiber_port.TraceOut;
type TODebug = fiber_port.TraceOutDebug;

export const childStructure = api.childStructure<CSIDOnly, CSFull, CSDebug>(namespace, resource);
export const connection = api.connection<ConnectionIDOnly, ConnectionFull, ConnectionDebug>(namespace, resource);
export const locatedIn = api.locatedIn<LocatedInIDOnly, LocatedInFull, LocatedInDebug>(namespace, resource);
export const parentStructure = api.parentStructure<PSIDOnly, PSFull, PSDebug>(namespace, resource);
export const retrieve = api.retrieve<IDOnly, Full, Debug>(namespace, resource);
export const stats = api.stats<fiber_port.Stats>(namespace, resource);
export const traceOut = api.traceOut<TOIDOnly, TOFull, TODebug>(namespace, resource);
