import { BBox, Coordinates, Feature, GeoJSON, Geometry } from 'lib/types/geojson';

function mergeBBoxes(b1: BBox, b2: BBox) {
  b1[0] = Math.min(b1[0], b2[0]);
  b1[1] = Math.min(b1[1], b2[1]);
  b1[2] = Math.max(b1[2], b2[2]);
  b1[3] = Math.max(b1[3], b2[3]);
}

function computeFromCoordinate(bbox: BBox, coordinate: Coordinates) {
  const x = coordinate[0];
  const y = coordinate[1];
  bbox[0] = Math.min(bbox[0], x);
  bbox[1] = Math.min(bbox[1], y);
  bbox[2] = Math.max(bbox[2], x);
  bbox[3] = Math.max(bbox[3], y);
}

function computeFromCoordinates(bbox: BBox, coordinates: Coordinates[]) {
  coordinates.forEach((coordinate) => computeFromCoordinate(bbox, coordinate));
}

function computeFromCoordinatesArray(bbox: BBox, array: Coordinates[][]) {
  const n = array.length;
  for (let i = 0; i < n; i++) {
    const coordinates = array[i];
    computeFromCoordinates(bbox, coordinates);
  }
}

function computeFromCoordinatesMatrix(bbox: BBox, matrix: Coordinates[][][]) {
  const n = matrix.length;
  for (let i = 0; i < n; i++) {
    const row = matrix[i];
    computeFromCoordinatesArray(bbox, row);
  }
}

function computeFromGeometry(bbox: BBox, geometry: Geometry | null) {
  if (geometry === null) {
    return;
  } else if (geometry.bbox !== undefined) {
    mergeBBoxes(bbox, geometry.bbox);
  } else {
    switch (geometry.type) {
      case 'LineString': computeFromCoordinates(bbox, geometry.coordinates); break;
      case 'MultiLineString': computeFromCoordinatesArray(bbox, geometry.coordinates); break;
      case 'MultiPoint': computeFromCoordinates(bbox, geometry.coordinates); break;
      case 'MultiPolygon': computeFromCoordinatesMatrix(bbox, geometry.coordinates); break;
      case 'Point': computeFromCoordinate(bbox, geometry.coordinates); break;
      case 'Polygon': computeFromCoordinatesArray(bbox, geometry.coordinates); break;
      case 'GeometryCollection': geometry.geometries.forEach((g) => computeFromGeometry(bbox, g)); break;
    }
  }
}

function computeFromFeature(bbox: BBox, feature: Feature<any>) {
  if (feature.bbox !== undefined) {
    mergeBBoxes(bbox, feature.bbox);
  } else {
    computeFromGeometry(bbox, feature.geometry);
  }
}

function computeFromFeatures(bbox: BBox, features: Array<Feature<any>>) {
  features.forEach((feature) => computeFromFeature(bbox, feature));
}

export function getBBox(data: GeoJSON<any>): BBox | undefined {
  if (data.bbox !== undefined) {
    return data.bbox;
  } else {
    const bbox: BBox = [Infinity, Infinity, -Infinity, -Infinity];
    switch (data.type) {
      case 'FeatureCollection': computeFromFeatures(bbox, data.features); break;
      case 'Feature': computeFromFeature(bbox, data); break;
      default: computeFromGeometry(bbox, data); break;
    }
    return bbox[0] === Infinity || bbox[1] === Infinity || bbox[2] === -Infinity || bbox[3] === -Infinity
         ? undefined
         : bbox;
  }
}
